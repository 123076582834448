<template>
  <div>
    <v-card>
      <v-card-title
        >{{ $_t("attribute.subjectResults") }}
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          v-show="!readonly"
          small
          fab
          @click="
            form.push({
              phaseTwoGoals: null,
              achievementTimeOne: null,
              achievementTimeTwo: null,
              phaseOneGoals: null,
              lessonName: null,
            })
          "
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </v-card-title>
      <v-container>
        <template v-if="form.length">
          <v-row v-for="(app, index) in form" class="mb-4" :key="index">
            <v-col cols="6">
              <v-select
                :items="lessons_array"
                item-text="name"
                item-value="value"
                outlined
                :readonly="readonly"
                dense
                hide-details
                :label="$_t('attribute.baseType')"
                v-model="app.lessonName"
              />
            </v-col>
            <v-col cols="6">
              <v-btn
                color="red"
                v-if="form.length > 1"
                class="float-end"
                outlined
                :readonly="readonly"
                small
                fab
                @click="deleteApp(index)"
                ><v-icon>mdi-minus</v-icon></v-btn
              >
            </v-col>
            <v-col cols="6">
              <v-text-field
                outlined
                :readonly="readonly"
                dense
                hide-details
                :label="$_t('attribute.phaseOneGoals')"
                v-model="app.phaseOneGoals"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                outlined
                :readonly="readonly"
                dense
                hide-details
                :label="$_t('attribute.achievementTimeOne')"
                v-model="app.achievementTimeOne"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                outlined
                :readonly="readonly"
                dense
                hide-details
                :label="$_t('attribute.phaseTwoGoals')"
                v-model="app.phaseTwoGoals"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                outlined
                :readonly="readonly"
                dense
                hide-details
                :label="$_t('attribute.achievementTimeTwo')"
                v-model="app.achievementTimeTwo"
              />
            </v-col>
          </v-row>
        </template>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { lessons_array } from "@/packages/admin/schema/calender/LESSONS_ARRAY";

export default {
  name: "ProgressStageSubjectResults",
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lessons_array,
      form: [
        {
          lessonName: null,
          phaseOneGoals: null,
          achievementTimeOne: null,
          phaseTwoGoals: null,
          achievementTimeTwo: null,
        },
      ],
    };
  },
  methods: {
    deleteApp(index) {
      this.form.splice(index, 1);
    },
    getSubjectOfResults() {
      return this.form;
    },
    setSubjectOfResults(form) {
      this.form = form;
    },
  },
  destroyed() {
    this.form = [
      {
        lessonName: null,
        phaseOneGoals: null,
        achievementTimeOne: null,
        phaseTwoGoals: null,
        achievementTimeTwo: null,
      },
    ];
  },
};
</script>

<style scoped></style>
