import { required } from "vuelidate/lib/validators";
import { levels } from "@/packages/admin/schema/LEVELS";

export const USER_INFO = {
  schema: [
    {
      id: "fullName",
      label: "fullName",
      placeholder: "",
      cols: 12,
      lg: 4,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "birthday",
      label: "birthday",
      placeholder: "",
      cols: 12,
      lg: 4,
      md: 12,
      sm: 12,
      type: "date",
    },
    {
      id: "school",
      label: "school",
      placeholder: "",
      cols: 12,
      lg: 4,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "grade",
      label: "grade",
      placeholder: "",
      cols: 12,
      lg: 4,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "abroadStudyDate",
      label: "abroadStudyDate",
      placeholder: "",
      cols: 12,
      lg: 4,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "abroadGrade",
      label: "abroadGrade",
      placeholder: "",
      cols: 12,
      lg: 4,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "passportID",
      label: "passportID",
      placeholder: "",
      cols: 12,
      lg: 4,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "passportExpireDate",
      label: "passportExpireDate",
      placeholder: "",
      cols: 12,
      lg: 4,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "level",
      label: "level",
      placeholder: "",
      cols: 12,
      lg: 4,
      md: 12,
      sm: 12,
      type: "select",
      show: true,
      items: [...levels],
      text: "text",
      value: "value",
    },
  ],

  /* Form MODEL */
  model: {
    fullName: null,
    birthday: null,
    school: null,
    grade: null,
    abroadStudyDate: null,
    abroadGrade: null,
    passportID: null,
    passportExpireDate: null,
    level: null,
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      fullName: { required },
      birthday: { required },
      school: { required },
      grade: { required },
      abroadStudyDate: { required },
      abroadGrade: { required },
      passportID: { required },
      level: {  },
      passportExpireDate: {  },
    },
  },
};
