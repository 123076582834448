<template>
  <div>
    <v-card>
      <v-card-title
        >{{ $_t("attribute.languageScore") }}
        <v-spacer />
      </v-card-title>
      <v-container>
        <template v-if="form.length">
          <v-row v-for="(app, index) in form" class="mb-4" :key="index">
            <v-col cols="4">
              <v-text-field
                outlined
                dense
                readonly
                hide-details
                :label="$_t('attribute.languageTest')"
                v-model="app.languageTest"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                outlined
                :readonly="readonly"
                dense
                hide-details
                :label="$_t('attribute.listening')"
                v-model="app.listening"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                outlined
                :readonly="readonly"
                dense
                hide-details
                :label="$_t('attribute.speaking')"
                v-model="app.speaking"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                outlined
                :readonly="readonly"
                dense
                hide-details
                :label="$_t('attribute.reading')"
                v-model="app.reading"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                outlined
                :readonly="readonly"
                dense
                hide-details
                :label="$_t('attribute.writing')"
                v-model="app.writing"
              />
            </v-col>
          </v-row>
        </template>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ProgressStageLangScore",
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: [
        {
          reading: null,
          writing: null,
          speaking: null,
          listening: null,
          languageTest: "IELTS/ TOFEL/PTE",
        },
        {
          reading: null,
          writing: null,
          speaking: null,
          listening: null,
          languageTest: "其他",
        },
      ],
    };
  },
  methods: {
    getLanguageScores() {
      return this.form;
    },
    setLanguageScores(form) {
      this.form = form;
    },
  },
  destroyed() {
    this.form = [
      {
        reading: null,
        writing: null,
        speaking: null,
        listening: null,
        languageTest: "IELTS/ TOFEL/PTE",
      },
      {
        reading: null,
        writing: null,
        speaking: null,
        listening: null,
        languageTest: "其他",
      },
    ];
  },
};
</script>

<style scoped></style>
