<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $_t("admin.ps.academicPlanningPlan") }}
        <v-spacer />
        <v-btn
          color="red"
          v-if="row.length > 1"
          v-show="!readonly"
          outlined
          small
          fab
          @click="deleteForm"
          ><v-icon>mdi-minus</v-icon></v-btn
        >
        <v-btn
          color="primary"
          v-show="!readonly"
          class="ml-3"
          outlined
          small
          fab
          @click="addForm"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </v-card-title>
      <v-container>
        <v-row class="mb-4 px-4">
          <table style="width: 100%">
            <thead>
              <tr>
                <th class="text-left" width="10%">
                  {{ $_t("admin.ps.planning") }}
                </th>
                <th class="text-left" width="20%">
                  {{ $_t("admin.ps.subject") }}
                </th>
                <th class="text-left" width="30%">
                  {{ $_t("admin.ps.teachingFocus") }}
                </th>
                <th class="text-left" width="25%">
                  {{ $_t("admin.ps.plan") }}
                </th>
                <th class="text-left" width="20%">
                  {{ $_t("admin.ps.evaluation") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(r, index) in row">
                <tr v-for="(item, i) in r.plansOfStudy" :key="item.key">
                  <td v-if="i === 0" :rowspan="r.plansOfStudy.length">
                    <div class="d-flex flex-column">
                      <div>
                        <v-text-field
                          v-model="r.from"
                          :label="$_t('attribute.from')"
                          placeholder="YYYY/MM/DD"
                          :readonly="readonly"
                          clearable
                        ></v-text-field>
                      </div>
                      <div>
                        <v-text-field
                          v-model="r.to"
                          :label="$_t('attribute.to')"
                          placeholder="YYYY/MM/DD"
                          :readonly="readonly"
                          clearable
                        ></v-text-field>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-space-between align-baseline">
                      <span>
                        <v-text-field
                          solo
                          hide-details
                          :readonly="readonly"
                          flat
                          class="rounded-0 elevation-0"
                          v-model="item.lessonName"
                        />
                      </span>
                      <span class="d-flex">
                        <v-btn
                          v-if="i === 0"
                          x-small
                          v-show="!readonly"
                          color="primary"
                          @click="addLesson(index)"
                          fab
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="r.plansOfStudy.length > 1"
                          dark
                          v-show="!readonly"
                          x-small
                          color="red"
                          class="ml-3"
                          @click="deleteLesson(index, i)"
                          fab
                        >
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>
                      </span>
                    </div>
                  </td>
                  <td>
                    <v-text-field
                      solo
                      hide-details
                      :readonly="readonly"
                      flat
                      class="rounded-0 elevation-0"
                      v-model="item.teachingFocus"
                    />
                  </td>
                  <td>
                    <v-text-field
                      solo
                      hide-details
                      :readonly="readonly"
                      flat
                      class="rounded-0 elevation-0"
                      v-model="item.plan"
                    />
                  </td>
                  <td>
                    <v-text-field
                      solo
                      hide-details
                      :readonly="readonly"
                      flat
                      class="rounded-0 elevation-0"
                      v-model="item.evaluation"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ProgressStagePlansOfStudy",
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fromMenu: false,
      toMenu: false,
      row: [
        {
          from: null,
          to: null,
          key: this.generateId(),
          plansOfStudy: [
            {
              lessonName: null,
              teachingFocus: null,
              plan: null,
              evaluation: null,
              key: this.generateId(),
            },
          ],
        },
      ],
    };
  },
  methods: {
    addForm() {
      this.row.push({
        from: null,
        to: null,
        key: this.generateId(),
        plansOfStudy: [
          {
            lessonName: null,
            teachingFocus: null,
            plan: null,
            evaluation: null,
            key: this.generateId(),
          },
        ],
      });
    },
    deleteForm() {
      this.row.splice(this.row.length - 1, 1);
    },
    addLesson(index) {
      this.row[index].plansOfStudy.push({
        lessonName: null,
        teachingFocus: null,
        plan: null,
        evaluation: null,
        key: this.generateId(),
      });
    },
    deleteLesson(index, i) {
      this.row[index].plansOfStudy.splice(i, 1);
    },
    getForm() {
      return this.row.map((r) => {
        return {
          ...r,
          key: null,
          plansOfStudy: r.plansOfStudy.map((p) => ({ ...p, key: null })),
        };
      });
    },
    setForm(row) {
      this.row = row;
    },
  },
  destroyed() {
    this.row = [
      {
        from: null,
        to: null,
        key: this.generateId(),
        plansOfStudy: [
          {
            lessonName: null,
            teachingFocus: null,
            plan: null,
            evaluation: null,
            key: this.generateId(),
          },
        ],
      },
    ];
  },
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 6px;
}
</style>
