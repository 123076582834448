<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <ProgressStageInfo readonly />
      </v-col>
      <v-col cols="12">
        <ProgressStageTargetOfStudy readonly />
      </v-col>
      <v-col cols="12">
        <ProgressStageSubjectResults readonly ref="subjectResults" />
      </v-col>
      <v-col cols="12">
        <ProgressStageLangScore readonly ref="languageScores" />
      </v-col>
      <v-col cols="12">
        <ProgressStagePlansOfStudy readonly ref="planingPlan" />
      </v-col>
      <v-col cols="12">
        <ProgressStagePlanning readonly ref="curriculumPlanning" />
      </v-col>
      <v-col cols="12">
        <ProgressStageMoreInformation readonly />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProgressStageInfo from "@/packages/admin/components/users/ProgressStageInfo";
import ProgressStageTargetOfStudy from "@/packages/admin/components/users/ProgressStageTargetOfStudy";
import ProgressStageSubjectResults from "@/packages/admin/components/users/ProgressStageSubjectResults";
import ProgressStageLangScore from "@/packages/admin/components/users/ProgessStageLangScore";
import ProgressStagePlansOfStudy from "@/packages/admin/components/users/ProgressStagePlansOfStudy";
import ProgressStagePlanning from "@/packages/admin/components/users/ProgressStagePlanning";
import ProgressStageMoreInformation from "@/packages/admin/components/users/ProgressStageMoreInformation";
import { mapGetters } from "vuex";
import { USER_INFO } from "@/packages/admin/schema/progressStage/USER_INFO.schema";
import { TARGET_OF_STUDY } from "@/packages/admin/schema/progressStage/TARGET_OF_STUDY.schema";
import { MORE_INFO } from "@/packages/admin/schema/progressStage/MORE_INFO.schema";
export default {
  name: "ProgressStage",
  components: {
    ProgressStageMoreInformation,
    ProgressStagePlanning,
    ProgressStagePlansOfStudy,
    ProgressStageLangScore,
    ProgressStageSubjectResults,
    ProgressStageTargetOfStudy,
    ProgressStageInfo,
  },
  computed: {
    ...mapGetters({
      user: "authentication/user",
    }),
  },
  data() {
    return {
      userInfo: USER_INFO.model,
      targetOfStudy: TARGET_OF_STUDY.model,
      description: MORE_INFO.model,
    };
  },
  methods: {
    async getStudentCoursePlan() {
      const res = await this.$actions.getStudentCoursePLan({
        publicKey: this.user.publicKey,
      });
      if (res) {
        this.lastUpdateUserFullName = res.lastUpdateUserFullName || null;
        this.updateMoment = res.updateMoment || null;
        this.coursePlanId = res.studentsCoursePlanId;

        this.userInfo.fullName = res.fullName;
        this.userInfo.birthday = res.birthday;
        this.userInfo.school = res.school;
        this.userInfo.grade = res.grade;
        this.userInfo.abroadStudyDate = res.abroadStudyDate;
        this.userInfo.abroadGrade = res.abroadGrade;
        this.userInfo.passportID = res.passportID;
        this.userInfo.passportExpireDate = res.passportExpireDate;
        this.userInfo.level = res.level;
        this.description.description = res.description;
        Object.assign(this.targetOfStudy, res.targetOfStudy);

        this.$refs.languageScores.setLanguageScores(res.languageScores);
        if (res.subjectResults.length)
          this.$refs.subjectResults.setSubjectOfResults(res.subjectResults);
        if (res.academicPlanningPlans.length)
          this.$refs.planingPlan.setForm(res.academicPlanningPlans);
        if (res.curriculumPlanningPlans.length) {
          this.$refs.curriculumPlanning.setForm(res.curriculumPlanningPlans);
          this.$refs.curriculumPlanning.setTeachers(
            res.curriculumPlanningPlans.map((c) => ({
              publicKey: c.teacherPublicKey,
              fullName: c.teacherName,
            }))
          );
        }
      }
    },
  },
  async mounted() {
    await this.getStudentCoursePlan();
  },
};
</script>

<style scoped></style>
