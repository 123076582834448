<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $_t("admin.ps.curriculumPlanning") }}
        <v-spacer />
        <v-btn
          color="red"
          v-show="!readonly"
          v-if="form.length > 1"
          outlined
          small
          fab
          @click="deleteForm"
        >
          <v-icon>mdi-minus</v-icon>
        </v-btn>
        <v-btn
          color="primary"
          v-show="!readonly"
          class="ml-3"
          outlined
          small
          fab
          @click="addForm"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </v-card-title>
      <v-container>
        <v-row v-for="(row, index) in form" :key="row.key">
          <v-col cols="6">
            <v-select
              hide-details
              v-model="row.subject"
              :items="lessons_array"
              :readonly="readonly"
              item-text="name"
              item-value="value"
              outlined
              dense
              :label="$_t('admin.ps.subjects')"
            />
          </v-col>
          <v-col cols="6">
            <v-select
              :items="teachers"
              item-value="publicKey"
              :item-text="
                (item) =>
                  user.role === 'Student'
                    ? `${item.fullName}`
                    : `${item.fullName} | ${item.nickName} | ${item.role}`
              "
              hide-details
              return-object
              :readonly="readonly"
              :value="row.teacherPublicKey"
              @change="
                row.teacherPublicKey = $event.publicKey;
                row.teacherName = `${$event.fullName} | ${$event.nickName} | ${$event.role}`;
              "
              outlined
              dense
              :label="$_t('admin.ps.teachersName')"
            >
              <template v-slot:prepend-item>
                <div class="px-3 py-2">
                  <v-text-field
                    outlined
                    :label="$_t('attribute.search')"
                    dense
                    hide-details
                    @input="getTeachers({ search: $event })"
                  />
                </div>
              </template>
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="[
                { text: $_t('admin.ps.online'), value: 'Online' },
                { text: $_t('admin.ps.offline'), value: 'Offline' },
              ]"
              hide-details
              v-model="row.studyType"
              :readonly="readonly"
              outlined
              dense
              :label="$_t('admin.ps.studyType')"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              hide-details
              v-model="row.booksForStudy"
              :readonly="readonly"
              outlined
              dense
              :label="$_t('admin.ps.booksForStudy')"
            />
          </v-col>
          <v-row class="mb-4 px-4">
            <v-col cols="12">
              <table style="width: 100%">
                <thead>
                  <tr>
                    <th class="text-left">
                      <span class="d-flex justify-space-between">
                        {{ $_t("admin.ps.topic") }}
                        <span>
                          <v-btn
                            x-small
                            v-show="!readonly"
                            color="primary"
                            @click="addTopic(index)"
                            fab
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="row.description.length > 1"
                            dark
                            x-small
                            v-show="!readonly"
                            color="red"
                            class="ml-3"
                            @click="deleteTopic(index)"
                            fab
                          >
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
                        </span>
                      </span>
                    </th>
                    <th class="text-left">
                      {{ $_t("admin.ps.hours") }}
                    </th>
                    <th class="text-left">
                      {{ $_t("admin.ps.actualHours") }}
                    </th>
                    <th class="text-left">
                      {{ $_t("admin.ps.remark") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in row.description" :key="i">
                    <td>
                      <v-text-field
                        solo
                        hide-details
                        :readonly="readonly"
                        flat
                        class="rounded-0 elevation-0"
                        v-model="item.topic"
                      />
                    </td>
                    <td>
                      <v-text-field
                        solo
                        hide-details
                        :readonly="readonly"
                        flat
                        class="rounded-0 elevation-0"
                        :value="item.hours"
                        @change="item.hours = Number($event)"
                        type="number"
                      />
                    </td>
                    <td>
                      <v-text-field
                        solo
                        hide-details
                        :readonly="readonly"
                        flat
                        class="rounded-0 elevation-0"
                        :value="item.actualHours"
                        @change="item.actualHours = Number($event)"
                        type="number"
                      />
                    </td>
                    <td>
                      <v-text-field
                        solo
                        hide-details
                        :readonly="readonly"
                        flat
                        class="rounded-0 elevation-0"
                        v-model="item.remark"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $_t("admin.ps.subtotal") }}</td>
                    <td>
                      {{
                        row.description
                          .map((d) => Number(d.hours))
                          .reduce((a, b) => a + b, 0)
                      }}
                    </td>
                    <td colspan="2">
                      {{
                        row.description
                          .map((d) => Number(d.actualHours))
                          .reduce((a, b) => a + b, 0)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { lessons_array } from "@/packages/admin/schema/calender/LESSONS_ARRAY";
import { mapGetters } from "vuex";

export default {
  name: "ProgressStagePlanning",
  computed: {
    ...mapGetters({
      teachers: "admin/get_teachers",
      user: "authentication/user",
    }),
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lessons_array,
      form: [
        {
          subject: null,
          teacherPublicKey: null,
          teacherName: null,
          studyType: null,
          booksForStudy: null,
          key: this.generateId(),
          description: [{ topic: "", hours: 0, actualHours: 0, remark: "" }],
        },
      ],
    };
  },
  methods: {
    addForm() {
      this.form.push({
        subject: null,
        teacherPublicKey: null,
        teacherName: null,
        studyType: null,
        booksForStudy: null,
        key: this.generateId(),
        description: [{ topic: "", hours: 0, actualHours: 0, remark: "" }],
      });
    },
    deleteForm() {
      this.form.splice(this.form.length - 1, 1);
    },
    addTopic(index) {
      this.form[index].description.push({
        topic: "",
        hours: 0,
        actualHours: 0,
        remark: "",
      });
    },
    deleteTopic(index) {
      this.form[index].description.splice(
        this.form[index].description.length - 1,
        1
      );
    },
    async getTeachers({ search = null }) {
      if (this.user.role === "Student") return;
      await this.$actions.getUsers({
        all: true,
        search: search,
        role: ["Teacher", "AcademicConsultant"],
        dispatch: "admin/setTeachers",
      });
    },
    getForm() {
      return this.form.map((f) => ({
        ...f,
        key: null,
        totalHours: f.description
          .map((d) => Number(d.hours))
          .reduce((a, b) => a + b, 0),
        totalActualHours: f.description
          .map((d) => Number(d.actualHours))
          .reduce((a, b) => a + b, 0),
      }));
    },
    setForm(form) {
      this.form = form;
    },
    setTeachers(teachers) {
      this.$store.commit("admin/updateTeachers", teachers);
    },
  },
  mounted() {
    this.getTeachers({});
  },
  destroyed() {
    this.form = [
      {
        subject: null,
        teacherPublicKey: null,
        teacherName: null,
        studyType: null,
        booksForStudy: null,
        key: this.generateId(),
        description: [{ topic: "", hours: 0, actualHours: 0, remark: "" }],
      },
    ];
  },
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 6px;
}
</style>
