export const MORE_INFO = {
  schema: [
    {
      id: "description",
      label: "moreInformation",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "textarea",
    },
  ],

  /* Form MODEL */
  model: {
    description: null,
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      description: {},
    },
  },
};
