export const TARGET_OF_STUDY = {
  schema: [
    {
      id: "targetUniversities",
      label: "targetUniversities",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "targetMajors",
      label: "targetMajors",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "targetHighSchools",
      label: "targetHighSchools",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "targetSubjects",
      label: "targetSubjects",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "text",
    },
  ],

  /* Form MODEL */
  model: {
    targetUniversities: "",
    targetMajors: "",
    targetHighSchools: "",
    targetSubjects: "",
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      targetUniversities: {},
      targetMajors: {},
      targetHighSchools: {},
      targetSubjects: {},
    },
  },
};
