<template>
  <div>
    <v-card>
      <v-card-title>{{ $_t("attribute.targetOfStudy") }}</v-card-title>

      <MainSoftForm>
        <template v-slot:body>
          <MyFormGenerator
            lg="10"
            :schema="schema"
            :validator="$v"
          ></MyFormGenerator>
        </template>
      </MainSoftForm>
    </v-card>
  </div>
</template>

<script>
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { TARGET_OF_STUDY } from "@/packages/admin/schema/progressStage/TARGET_OF_STUDY.schema";
export default {
  name: "ProgressStageTargetOfStudy",
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: { MyFormGenerator, MainSoftForm },
  data() {
    return {
      form: TARGET_OF_STUDY.model,
      schema: TARGET_OF_STUDY.schema,
    };
  },
  validations: {
    ...TARGET_OF_STUDY.validations,
  },
  mounted() {
    if (this.readonly) {
      this.schema.forEach((f) => (f.readonly = true));
    }
  },
  destroyed() {
    for (const formKey in this.form) {
      this.form[formKey] = "";
    }
  },
};
</script>

<style scoped></style>
